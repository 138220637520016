<template>
  <v-data-table
    :headers="headers"
    :items="listWo"
    sort-by="no"
    dense
    @click:row="onClickRow"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="6">
            <v-text-field
              flat
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="6">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" dark rounded :to="{ name: 'Create Wo' }">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>Create</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ listWo.findIndex(x => x === item) + 1 }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "wo",
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "WO#",
        value: "id",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Supervisor",
        value: "supervisorName",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Created At",
        value: "createdAt",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    search: null,
  }),

  computed: {
    ...mapState("wo", ["listWo"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("wo/getAll")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail Wo", params: { id: item.id } });
    },
  },
};
</script>

<style></style>
